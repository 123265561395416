<template>
  <base-section id="virtualcard">

    <!-- <base-img
      :src="require('@/assets/not-found.png')"
      class="mx-auto"
      contain
      height="550"
      width="450"
    /> -->

    <div class="text-center">
      <base-btn :to="{ name: 'beranda' }">
        Riva
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'Vcard',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
